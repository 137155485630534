<template>
  <main class="main news pt-3" v-if="articlesList">
    <!-- <page-slider
      :slides="articlesList[0].acf.banner_group.slides"
    ></page-slider> -->

    <b-container fluid="xl" class="my-5 pt-5">
      <section-title>Auxiliary Equipment</section-title>
    </b-container>

    <b-container fluid="xl" class="px-0">
      <articles-list-item v-for="(item, index) in articlesList" :article="item">
        <b-container fluid="xl" class="my-3 px-10 px-md-5 px-xl-2">
          <divider class="bg-silver" />
        </b-container>
      </articles-list-item>

      <!-- <b-container fluid="xl" class="px-0 my-3 text-center">
        <a href="" class="px-0 my-5 text-uppercase more">
          <span>see more</span>
          <br />
          <img src="/images/icons/common/icon-down-arrow.svg" alt="" />
        </a>
      </b-container> -->
    </b-container>
  </main>
</template>

<script>
import axios from "@/axios";
import PageSlider from "../components/PageSlider";
import SectionTitle from "../components/SectionTitle.vue";
import FormCategoryMarket from "../components/FormCategoryMarket";
import ArticlesListItem from "../components/SearchListItem";
import Divider from "../components/Divider";

export default {
  components: {
    PageSlider,
    SectionTitle,
    FormCategoryMarket,
    ArticlesListItem,
    Divider,
  },
  data() {
    return {
      backendUrl: process.env.VUE_APP_BACKEND_URL,
      currentPageSlug: "news",
      pageData: [],
      articlesList: [],
      spare_part_category: "",
      market_category: "",
      per_page: 5,
      page: 1,
    };
  },
  created() {
    this.getNews();
  },
  methods: {
    getNews() {
      axios
        // .get(this.backendUrl + "pages?slug=" + this.currentPageSlug)
        .get(`auxiliary-equipment`)
        .then((response) => {
          console.log(response);
          let object = response.data;
          this.articlesList = object;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>

<style scoped>
.more {
  font-weight: 700;
}
</style>
